/**************
    FOOTER
***************/

footer {
    display: flex;
    padding: 6% 0%;
  }
  
  .footer__row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer__logo--img {
    width: 70px;
    filter: invert(1);
  }
  
  .footer__social--link, .footer__copyright {
    color: #fff;
  }
  
  .footer__social--list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    max-width: 500px;
    margin: 28px 0;
  }