/**********
  NAVBAR
**********/

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100px;
  padding: 0 20px;
}

.personal-logo {
  width: 50px;
  height: 50px;
}

.nav__link--list {
  display: flex;
}

.nav__link {
  margin: 0 12px;
}

.nav__link--anchor {
  font-size: 16px;
  color: #242424;
  font-weight: 700;
}

.fa-adjust {
  font-size: 20px;
}

@media (max-width: 480px) {
  .nav__link:first-child {
    display: none;
  }
}