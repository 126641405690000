/**************
    PROJECTS
***************/

.project {
  margin-bottom: 135px;
  padding: 0 16px;
  flex: none;
  width: 50%;
}

.project__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.project__img {
  width: 100%;
  transition: all 600ms ease;
}

.project__wrapper {
  position: relative;
  border-radius: 20px;
  box-shadow: 0 10px 40px 0 rgb(0, 0, 0, 0.45);
  overflow: hidden;
  height: 100%;
  max-height: 309px;
}

.project__wrapper:hover .project__description {
  opacity: 1;
  transform: translateY(-50%);
}

.project__wrapper:hover .project__img {
  transform: scale(1.07);
  filter: blur(5px);
}

.project__description {
  position: absolute;
  top: 50%;
  left: 90px;
  transform: translateY(100%);
  opacity: 0;
  transition: opacity 300ms, transform 450ms ease;
  color: #fff;
  max-width: 550px;
}

.project__wrapper--bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1c1d25;
  opacity: 0;
  transition: all 450ms ease;
  visibility: hidden;
}

.project__wrapper:hover .project__wrapper--bg {
  opacity: 0.7;
  visibility: visible
}

.project__description--title {
  font-size: 40px;
}

.project__description--sub-title {
  margin-top: 8px;
  margin-bottom: 16px;
  max-width: 300px;
}

.project__description--link {
  color: #fff;
  font-size: 20px;
  margin-right: 16px;
}

.project__description--para {
  margin: 16px 0;
}

.project__link {
  color: #85ccc9;
}

@media (max-width: 1200px) {
  .project__wrapper {
    max-height: 227px;
  }
}

@media (max-width: 912px) {
  .project__description {
    left: 25px;
  }
  .project__wrapper {
    max-height: 187px;
  }
}

@media (max-width: 768px) {
  .project {
    width: 100%;
  }
  .project__description {
    left: 90px;
  }
  .project__wrapper {
    max-height: 309px;
  }
}

@media (max-width: 480px) {
  .project__description {
    left: 0;
    padding: 0;
    width: 100%;
    text-align: center;
  }
  .project__description--sub-title {
      margin-bottom: 8px;
  }
  .project__description--sub-title {
    max-width: 100%;
    padding: 0 16px;
  }
  .project__description--links {
    margin-top: 16px;
  }
  .project__wrapper {
    max-height: 170px;
  }
}