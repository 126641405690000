/****************
  LANDING PAGE
****************/

#landing-page {
    min-height: 100vh;
  }
  
  .header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1000px;
    padding: 0 30px;
  }
  
  .header,
  .mail-btn {
    z-index: 10;
  }
  
  .title {
    font-size: 120px;
    margin-bottom: 12px;
    text-align: left;
    line-height: 1;
  }
  
  .title--secondary, .highlighted-text {
    color: #1C4442;
    position: relative;
  }
  
  .title-position {
    font-weight: 700;
    letter-spacing: 4px;
  }
  
  .header__para {
    font-size: 24px;
    line-height: 2;
    max-width: 480px;
    font-weight: 400;
  }
  
  .social__list {
    margin-top: 16px;
    display: flex;
  }
  
  .social__link {
    background-color: #2c3a47;
    color: #fff;
    padding: 6px;
    width: 32px;
    height: 32px;
    font-size: 14px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  
  .mail__btn {
    width: 70px;
    height: 70px;
    font-size: 32px;
    border-radius: 50%;
    border: none;
    background-color: #242424;
    color: #fff;
    position: fixed;
    bottom: 32px;
    right: 40px;
    z-index: 100;
    box-shadow: 0 12px 30px 0 rgb(0, 0, 0, 0.2);
  }
  
  .mail {
    color: #fff;
  }
  
  .scroll {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .scroll__icon {
    width: 20px;
    height: 30px;
    border: 2px solid #fff;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .scroll__icon:after {
    content: '';
    width: 4px;
    height: 6px;
    background-color: #fff;
    border-radius: 2px;
    animation: scroll 1000ms infinite alternate-reverse
  }
  
  @keyframes scroll {
    0% {
      transform: translateY(3px)
    }
    100% {
      transform: translateY(-3px);
    }
  }

  @media (max-width: 768px) {
    .title {
      font-size: 80px;
    }
    .header__para {
      font-size: 20px;
    }
  }

  @media (max-width: 480px) {
    .title {
      font-size: 52px;
    }
    .header__para {
      font-size: 18px;
    }
  }