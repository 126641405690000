/**************
    ABOUT ME
***************/

.about__me--wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }
  
  .about__me--panel {
    width: calc(100% / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 24px;
  }
  
  
  .about__me-header {
    margin: 16px 0;
    text-align: center;
  }
  
  .about__me-para {
    text-align: center;
  }
  
  .about__me--languages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .about__me--language {
    width: 25%;
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 300ms ease;
  }
  
  .about__me--language:hover {
    filter: brightness(80%);
    transform: scale(0.9)
  }
  
  .about__me--language:hover .language__name {
    transform: scale(1)
  }
  
  .language__name {
    position: absolute;
    bottom: -10px;
    transform: scale(0);
    transition: all 300ms ease;
  }
  
  .about__me--language--img {
    width: 48%;
  }
  
  .css__avatar--img {
    width: 70px;
    height: 70px;
  }

  @media (max-width: 1008px) {
    .about__me--language {
      width: 33%;
    }
  }

  @media (max-width: 768px) {
    .about__me--wrapper {
      flex-wrap: wrap;
    }
    .about__me--panel {
      width: 100%;
    }
    .about__me-para {
      margin-bottom: 32px;
    }
    .about__me--language {
      width: 16%;
      margin-bottom: 32px;
    }
  }

  @media (max-width: 480px) {
    .about__me--language {
      width: 33%;
      margin: 0;
    }
    .about__me--panel {
      margin-top: 32px;
    }
  }